export interface INoShowDisplayDate {
    day: number;
    month: string;
    year: number;
}
export function getNoShowDisplayDate(): INoShowDisplayDate {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return {
        day, month, year
    };
}
