import { useState } from "react";
import SearchField from "../../../components/common/SearchFiled/SearchField";
import "./addRental.scss";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

const SearchBar = (props: any) => {
    const appInsights = useAppInsightsContext();
    const tabs = ["Article number", "Item number"];
    const [activeTab, setActiveTab] = useState("Article number");
    const trackSearchTabOpen = useTrackEvent(appInsights, "Active tab to search in shopping bag", {});
    const setSelecetedTab = (tab: string) => {
        setActiveTab(tab);
        trackSearchTabOpen({"activeTab": activeTab});
        return props.activeTab(tab);
    };


    return (
        <div className="searchBarContent">
            <div className="tabsStyle">
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        onClick={() => {
                            setSelecetedTab(tab);
                        }}
                        className="tabStyle"
                        style={{
                            color: activeTab === tab ? "#D6001C" : "",
                            borderBottom: activeTab === tab ? "1px solid" : "",
                        }}
                    >
                        {tab}
                    </div>
                ))}
            </div>

            <div className="searchBarStyle">
                <SearchField
                    name="search"
                    width="100%"
                    height="44px"
                    value={props.value}
                    placeholder={activeTab === "Article number" ? "Type article no. (1234567890)" : "Type item no. (1234567890-001-001)"}
                    onChange={props.onChange}
                    onKeyPress={props.onKeyPress}
                    onClearClick={props.onClearClick}
                    showInfo={props.showInfo}
                />
            </div>
        </div>
    );
};

export default SearchBar;
