import React from "react";
import { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./components/common/Header/DesktopHeader/Header";
import SideDrawer from "./components/common/SideDrawer/SideDrawer.jsx";
// import BackDrop from "./components/common/BackDrop/BackDrop.js";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import RentalList from "./Pages/RentalManegment/RentalList/RentalList";
import "./App.css";
import MemberId from "./Pages/ShoppingBag/Member/MemberId";
import OrderDetails from "./Pages/RentalManegment/OrderDetails/OrderDetails";
import AddRental from "./Pages/ShoppingBag/AddRental/AddRental";
import RentalBag from "./Pages/ShoppingBag/RentalBag/RentalBag";
import MemberPage from "./Pages/RentalManegment/MemberPage/MemberPage";
import ReturnOrder from "./Pages/RentalManegment/ReturnOrder/ReturnOrder";
import RentalSummary from "./Pages/ShoppingBag/RentalSummary/RentalSummary";
import ErrorScreen from "./Pages/ErrorScreen/ErrorScreen";
import ReportNoShow from "./Pages/NoShow/NoShow";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./Appinsights";
const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const sideDrawerButtonToggleHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  // const backDropClickHandler = () => {
  //   setSideDrawerOpen(false);
  // };

  // let backDrop;
  if (sideDrawerOpen) {
    // backDrop = <BackDrop backDropClick={backDropClickHandler} />;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
    <Router>
      <div className="app">
        <Header sideDrawerButtonToggle={sideDrawerButtonToggleHandler}></Header>

        <SideDrawer show={sideDrawerOpen} />
        {/* {backDrop} */}
        <Switch>
          <PrivateRoute exact path="/" Component={AddRental} />
          <PrivateRoute exact path="/rental-management/rental-list" Component={RentalList} />
          <PrivateRoute exact path="/shopping-bag/add-member" Component={MemberId} />
          <PrivateRoute exact path="/rental-management/order-details/:orderId" Component={OrderDetails} />
          <PrivateRoute exact path="/rental-management/order-details/:orderId/return" Component={ReturnOrder} />
          <PrivateRoute exact path="/rental-management/order-details/:orderId/businessPartnerId/:businessPartnerId" Component={MemberPage} />
          <PrivateRoute exact path="/shopping-bag/add-rental" Component={AddRental} />
          <PrivateRoute exact path="/shopping-bag/rental-bag" Component={RentalBag} />
          <PrivateRoute exact path="/shopping-bag/rental-summary" Component={RentalSummary} />
          <PrivateRoute exact path="/shopping-bag/error" Component={ErrorScreen} />
          <PrivateRoute exact path="/rental-management/error" Component={ErrorScreen} />
          <PrivateRoute exact path="/no-show" Component={ReportNoShow} />
        </Switch>
      </div>
    </Router>
    </AppInsightsContext.Provider>
  );
};

export default App;
