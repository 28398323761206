import axios, { AxiosResponse } from "axios";
import { IItem, IItemResponseDTO } from "../model/item";
import { IResponseEntity } from "../model/response-entity";

export function fetchItem(
  storeId: string,
  articleNumber: number
): Promise<IResponseEntity<IItem[]>> {
  return axios
    .get(`/inventoryApi/rentals/brand/{rentalBrandId}/store/${storeId}/inventory/items?size=100&&activeOnly=true&&articleId=${articleNumber}`)
    .then((itemResponse: AxiosResponse<IItemResponseDTO>) => {
      const items = dataFromItemResponse(itemResponse.data);
      const memberEntity: IResponseEntity<IItem[]> = {
        data: items,
        status: itemResponse.status,
        statusText: itemResponse.statusText,
        error: null,
      };

      return memberEntity;
    });
}

export function getArticleItemsByArticleId(
  storeId: string,
  Articlenumber: number
): Promise<IResponseEntity<IItem[]>> {
  return axios
    .get(`/inventoryApi/rentals/brand/{rentalBrandId}/store/${storeId}/inventory/items?articleId=${Articlenumber}&size=100&activeOnly=true`)
    .then((itemResponse: AxiosResponse<IItemResponseDTO>) => {
      const items = dataFromItemResponse(itemResponse.data);
      const memberEntity: IResponseEntity<IItem[]> = {
        data: items,
        status: itemResponse.status,
        statusText: itemResponse.statusText,
        error: null,
      };

      return memberEntity;
    });
}

export function getArticleItemsByItemId(
  storeId: string,
  itemNumber: number
): Promise<IResponseEntity<IItem[]>> {
  return axios
    .get(`/inventoryApi/rentals/brand/{rentalBrandId}/store/${storeId}/inventory/items?rentalItemId=${itemNumber}&activeOnly=true`)
    .then((itemResponse: AxiosResponse<IItemResponseDTO>) => {
      const items = dataFromItemResponse(itemResponse.data);
      const memberEntity: IResponseEntity<IItem[]> = {
        data: items,
        status: itemResponse.status,
        statusText: itemResponse.statusText,
        error: null,
      };

      return memberEntity;
    });
}


export function postOrder(
  // eslint-disable-next-line no-unused-vars
  data: typeof Array,
  storeId: string
): Promise<IResponseEntity<IItem[]>> {
  return axios
    .post(`/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/order`)
    .then((orderRes: AxiosResponse<never>) => {
      const orderEntity: IResponseEntity<IItem[]> = {
        data: orderRes.data,
        status: orderRes.status,
        statusText: orderRes.statusText,
        error: null,
      };

      return orderEntity;
    });
}
function dataFromItemResponse(data: IItemResponseDTO): IItem[] {
  return data._embedded.rentalItemModelList;
}


