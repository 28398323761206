import Button from "../../components/common/Button/Button";
import { useEffect, useState } from "react";
import MobileHeader from "../../components/common/Header/MobileHeader/MobileHeader";
import Label from "../../components/common/Label/Label";
import TextField from "../../components/common/TextField/TextField";
import "./noShow.scss";
import { NoShow, NoShowDto } from "../../model/no-show";
import { deleteReportedNoShow, getNoShowData, reportNoShow } from "../../services/no-show";
import DisplayNoShow from "./DisplayNoShow";
import { getNoShowDisplayDate, INoShowDisplayDate } from "../../Utils/date-util";
import Loader from "../../components/common/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../app/root-reducer";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

function ReportNoShow() {
    const appInsights = useAppInsightsContext();
    const [noShowSlots, setNoShowSlots] = useState<NoShowDto>();
    const [noOfNoShows, setNoOfNoShows] = useState<number>(0);
    const [reportedNoShow, setReportedNoShow] = useState<NoShow>();
    const [loading, setLoading] = useState(false);
    const storeId = useSelector((state: RootState) => state.store.selectedRentalStore.storeId);
    const displayDate: INoShowDisplayDate = getNoShowDisplayDate();
    const [errorMsg, setErrorMsg] = useState("");
    const [error, setError] = useState(false);
    const trackReportNoShow = useTrackEvent(appInsights, "Report NoShow", reportedNoShow);
    const trackDeleteReportedNoShow = useTrackEvent(appInsights, "Delete Reported NoShow", {});
    useEffect(() => {
        fecthReportedNoShowSlots();
    }, [reportedNoShow]);

    const fecthReportedNoShowSlots = async () => {
        setLoading(true);
        const noShowData = await getNoShowData(storeId);
        setLoading(false);
        setNoShowSlots(noShowData.data);
    };

    const handleSaveButtonClick = async () => {
        if (+noOfNoShows <= 0 || +noOfNoShows > 99) {
            setErrorMsg("Wrong amount number");
            setError(true);
            return;
        } else {
            setError(false);
        }
        const noShow: NoShow = { amount: noOfNoShows, storeId: storeId };

        setLoading(true);
        const res = await reportNoShow(noShow, storeId);
        trackReportNoShow(noShow);
        setLoading(false);
        setReportedNoShow(res.data);
        setNoOfNoShows(0);
    };

    const handleDeleteReportedSlot = async (noShowId: number | undefined) => {
        if (noShowId) {
            setLoading(true);
            await deleteReportedNoShow(storeId, noShowId);
            trackDeleteReportedNoShow({storeId: storeId, noShowId: noShowId});
            setLoading(false);
            fecthReportedNoShowSlots();
        } else {
            console.log("no id found to be deleted ");
        }
    };


    return (
        < div className="no-show-container" >
            <MobileHeader pageName="Report no show" justifyContent="left" padding="0 0 6px 20px" fontSize="16px" />
            <div className="no-show-content">
                <Label text="Tell us how many customers did not come" fontSize="24px" bold padding="0 0 24px 0" textAlign="center" />
                <Label text="Only active for today:" fontSize="16px" padding="0 0 7px 0" />
                <Label text={`${displayDate.day} ${displayDate.month} ${displayDate.year}`} fontSize="16px" bold />
            </div>
            <div className="no-show-input">
                <Label text="Amount of customers" fontSize="13px" padding="7px 0" />
                <div className="no-show-input-content">
                    <TextField
                        type="number"
                        height="47px"
                        width="174px"
                        value={noOfNoShows}
                        onChange={(value: number) => setNoOfNoShows(value)}
                        onKeyPress={() => { }}
                        placeholder="Type in amount"
                        fontWeight="normal"
                        padding="0 0 0 10px"
                    />
                    <Button text="Save" width="174px" height="48px" type="primary" margin="0px 0px 0px 16px"
                        fontSize="14px" onButtonClick={() => handleSaveButtonClick()} />
                </div>
                <div className="errormsg">
                    {error ? <Label text={errorMsg} error /> : null}
                </div>

                <div className="line"></div>
                <div className="no-show-amount">
                    <Label text=" Number of no shows today:" fontSize="20px" padding="0 0 15px 0" bold />
                    <Label text={noShowSlots?.amount} fontSize="40px" bold />
                </div>
                {loading ? (<>
                    <Loader></Loader>
                </>) : ""}
                {!loading && noShowSlots && noShowSlots.noShowList && noShowSlots.noShowList.length > 0 &&
                    <DisplayNoShow noShows={noShowSlots.noShowList}
                        deleteReportedSlot={(id: number | undefined) => handleDeleteReportedSlot(id)}></DisplayNoShow>
                }
            </div>
        </div >
    );
}
export default ReportNoShow;


