import "./button.scss";

const Button = (props) => {
  return (
    <button
      onClick={props.onButtonClick}
      type={props.type}
      id={props.id}
      className={props.type}
      disabled={props.disabled}
      style={{
        width: props.width,
        height: props.height,
        backgroundColor: props.backgroundColor,
        color: props.color,
        border: props.border,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        textDecoration: props.textDecoration,
        margin: props.margin,
        fontFamily: props.fontFamily,
        letterSpacing: props.letterSpacing,
        zIndex: props.zIndex,
        right: props.right,
        position: props.position
      }}
    >
      {props.text}
    </button >
  );
};

export default Button;
