import { RentalDays } from "../model/order";
import { IOption } from "../model/store";

export function getDays(): RentalDays[] {
    return [
        {
            id: 1,
            value: 5,
        },
        {
            id: 2,
            value: 10,
        },
        {
            id: 3,
            value: 15,
        },
        {
            id: 4,
            value: 20,
        }
    ];
}

export function getCheckBoxes() {
    return [
        {
            id: 1,
            value: "Sign agreement",
            checked: false,
        },
        {
            id: 2,
            value: "Payment",
            checked: false
        }
    ];
}


export function getDropdownOptionsFromSameKeyValue(options: Array<number>) {
    let dropdownOptions: IOption[];
    if (options?.length > 0) {
        dropdownOptions = options.map((option) => {
            return (
                { id: option, value: option }
            );
        });
        return dropdownOptions;
    }
}
