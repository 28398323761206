/* eslint-disable no-useless-escape */
import { useState } from "react";
import "./email.scss";
import Label from "../Label/Label";
import emailIcon from "../../../assets/images/email.svg";
import xIcon from "../../../assets/images/xIcon.svg";
import checkIcon from "../../../assets/images/checkIcon.svg";

import IMG from "../Image/IMG";

const Email = (props) => {
  const [error, setError] = useState(null);
  const isValidEmailAddress = (address) => {
    const mail = address.trim();
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(mail)) {
      props.isValid(true);
      setError(false);
      props.onChange(mail);
      return true;
    } else {
      props.isValid(false);
      setError(true);
      return false;
    }
  };

  return (
    <div className="emailContainer">
      <div className={
        error === null || props.existing === "" ?
          "contaniar" : !error && props.existing === "yes" ?
            ["contaniar", "correct"].join(" ") : ["contaniar", "error"].join(" ")}
      >
        <div className="icon">
          <IMG src={emailIcon} />
        </div>
        <div className="input_text">
          <input
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            className={error && !error ? ["inputBox", "correct"].join(" ") : ["inputBox", "error"].join(" ")}
            style={{
              width: props.width,
              height: props.height
            }}
            disabled={props.disabled}
            onChange={(event) => isValidEmailAddress(event.target.value)}
          />
          <div className={
            error === null && !props.existing === "yes" || props.existing === "" ?
              ["icon", "noneDisplay"].join(" ") : ["icon", "blockDisplay"].join(" ")}
          >
            <IMG
              src={!error && props.existing === "yes" ? checkIcon : xIcon}
            />
          </div>
        </div>

        {props.error ? <Label text={props.errorMsg} error /> : null}
      </div>
    </div>
  );
};

export default Email;
