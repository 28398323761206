
import { getStoresAndRentalBrandIdFromApi } from "../services/brandId-service";

export const getBrandId = async () => {
  const brandId = sessionStorage.getItem("rentalBrandId");
  if (brandId) {
    return brandId;
  }
  const brandIdRes = await getStoresAndRentalBrandIdFromApi();
  if (brandIdRes) {
    sessionStorage.setItem("rentalBrandId", Object.assign({}, brandIdRes).rentalBrandId);
    return Object.assign({}, brandIdRes).rentalBrandId;
  }
  return "0-0";
};
