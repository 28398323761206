import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import "./authInterceptor.js";
import "./authTokenRefresh.js";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_RENTAL_SHOPPINGBAG_CLIENT_ID ? process.env.REACT_APP_RENTAL_SHOPPINGBAG_CLIENT_ID : "",
    authority: process.env.REACT_APP_RENTAL_SHOPPINGBAG_AUTHORITY ? process.env.REACT_APP_RENTAL_SHOPPINGBAG_AUTHORITY : "",
    redirectUri: process.env.REACT_APP_RENTAL_SHOPPINGBAG_REDIRECT_URI ? process.env.REACT_APP_RENTAL_SHOPPINGBAG_REDIRECT_URI : "",
    postLogoutRedirectUri: process.env.REACT_APP_RENTAL_SHOPPINGBAG_POST_LOGOUT_URI ? process.env.REACT_APP_RENTAL_SHOPPINGBAG_POST_LOGOUT_URI : ""
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    tokenRenewalOffsetSeconds: process.env.REACT_APP_RENTAL_SHOPPINGBAG_TOKEN_RENEWALOFFSET_SECONDS
      ? +process.env.REACT_APP_RENTAL_SHOPPINGBAG_TOKEN_RENEWALOFFSET_SECONDS
      : 600,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const getIdToken = async () => {
  let tokenResponse = null;
  const expiry = msalInstance.getAllAccounts()[0].idTokenClaims["exp"] * 1000;
  const tokenOffset = (+process.env.REACT_APP_RENTAL_SHOPPINGBAG_TOKEN_RENEWALOFFSET_SECONDS + 5) * 1000;
  if (expiry - tokenOffset <= Date.now()) {
    const idTokenrequest = {
      scope: process.env.REACT_APP_RENTAL_SHOPPINGBAG_APPLICATION_ID_URI + "/.default",
      account: msalInstance.getAllAccounts()[0]
    };
    tokenResponse = await msalInstance.acquireTokenSilent(idTokenrequest);
  } else {
    const accessTokenrequest = {
      scopes: ["User.Read"],
      account: msalInstance.getAllAccounts()[0]
    };
    tokenResponse = await msalInstance.acquireTokenSilent(accessTokenrequest);
  }
  return tokenResponse.idToken;
};

export const getAccessToken = async (scope) => {
  const accessTokenrequest = {
    scopes: [scope],
    account: msalInstance.getAllAccounts()[0]
  };
  const tokenResponse = await msalInstance.acquireTokenSilent(accessTokenrequest);
  return tokenResponse.accessToken;
};

export const msalInstance = new PublicClientApplication(msalConfig);
