
const IMG = (props) => {
  return <img style={{
    width: props.width,
    height: props.height,
    objectFit: props.fit,
    position: props.position,
    right: props.right
  }}
    src={props.src} />;
};

export default IMG;
