import "./textField.scss";
import Label from "../Label/Label";

const TextField = (props) => {
  return (
    <div className="textFieldContent" style={{ width: props.width, height: props.height }}>
      <Label text={props.label} />
      <input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        className={props.error ? ["inputBox", "error"].join(" ") : "inputBox"}
        style={{
          width: props.width,
          height: props.height,
          border: props.noBorder ? "none" : "1px solid #d0d0d0",
          backgroundColor: props.bg ? props.bg : "#fff",
          fontWeight: props.fontWeight,
          padding: props.padding,
          margin: props.margin
        }}
        disabled={props.disabled}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          props.onKeyPress(event);
        }}
        value={props.value ? props.value : ""}
      />
      {props.error ? <Label text={props.errorMsg} error /> : null}
    </div>
  );
};

export default TextField;
