/* eslint-disable prettier/prettier */
import axios from "axios";

export function fetchMemberDetails(memberEmail: string, country: string, storeId: string) {
  return axios({
    url: `/shoppingBagApi/rentals/brand/{rentalBrandId}/country/${country}/store/${storeId}/emailAddress`,
    method: "get",
    headers: {
      emailAddress: memberEmail
    }
  });
}
export function fetchMemberDetailsById(memberId: string, country: string, storeId: string) {
  return axios({
    url: `/shoppingBagApi/rentals/brand/{rentalBrandId}/country/${country}/store/${storeId}/businessPartnerId`,
    method: "get",
    headers: {
      businessPartnerId: memberId
    }
  });
}
