import {
  CASH_OFFICE_RESPONSIBLE_ROLE,
  DEPARTMENT_MANAGER_ROLE,
  ROLE_ASSORTMENT_READ,
  ROLE_ASSORTMENT_WRITE,
  ROLE_INVENTORY_ADMIN,
  ROLE_INVENTORY_READ,
  SALES_ADVISOR_ROLE,
  STORE_MANAGER_ROLE,
  VISUAL_MERCHANDISER_ROLE
} from "./AuthConstants.js";

const storeRoleMap = {
  1: STORE_MANAGER_ROLE,
  2: DEPARTMENT_MANAGER_ROLE,
  3: CASH_OFFICE_RESPONSIBLE_ROLE,
  4: VISUAL_MERCHANDISER_ROLE,
  5: SALES_ADVISOR_ROLE
};
export const isAssortmentUser = (roles) => {
  return roles && (roles.includes(ROLE_ASSORTMENT_READ) || roles.includes(ROLE_ASSORTMENT_WRITE));
};

export const isInventoryUser = (roles) => {
  return roles && (roles.includes(ROLE_INVENTORY_READ) || roles.includes(ROLE_INVENTORY_ADMIN));
};

export const getAuthProfile = (roles, extnAttribute7) => {
  const user = {};
  const storeRolesData = {};
  user.assignedRoles = roles;
  if (extnAttribute7) {
    const storeAssignments = extnAttribute7.split("|");
    storeAssignments.forEach((assignment) => {
      const assignmentData = assignment.split(":");
      const storeRole = storeRoleMap[assignmentData[0]];
      const storeRoleData = storeRolesData[storeRole];
      const existingRoleData = storeRoleData ? storeRoleData : [];
      existingRoleData.push(assignmentData[4] + assignmentData[1]);
      storeRolesData[storeRole] = existingRoleData;
    });
  }
  user.storeRolesData = storeRolesData;
  return user;
};
