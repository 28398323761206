import Label from "../../common/Label/Label";
import "./memberDetails.scss";

const MemberDetails = (props) => {
  const member = props.data ? props.data : {};
  return (
    <div className="member_container">
      <div className="title">
        <Label text={props.title} fontSize="20px" bold />
      </div>
      <div className="row">
        <Label text="First name" fontSize="13px" />
        <Label text={member.firstName} bold fontSize="16px" />
      </div>
      <div className="row">
        <Label text="Last name" fontSize="13px" />
        <Label text={member.lastName} bold fontSize="16px" />
      </div>
      <div className="row">
        <Label text="Email address" fontSize="13px" />
        <Label text={member.emailAddress} bold fontSize="16px" />
      </div>
      <div className="row">
        <Label text="Member ID" fontSize="13px" />
        <Label text={member.customerLoyaltyId} bold fontSize="16px" />
      </div>
    </div>
  );
};

export default MemberDetails;
