import React from "react";
import BackDrop from "../BackDrop/BackDrop";
import Button from "../Button/Button";
import "./modal.scss";

interface IModalProps {
  children?: React.ReactNode
  show?: boolean;
  maxHeight?: string;
  title?: string;
  message: string;
  itemStatus?: string;
  onYesButtonClicked(): void;
  onNoButtonClicked(): void;
}

const Modal = (props: IModalProps) => {
  const handelCloseClick = () => {
    return props.onNoButtonClicked();
  };

  return (
    props.show ? (<>
      <BackDrop />
      <div className="modalContainer">
        <div
          className="content"
          style={{
            maxHeight: props.maxHeight
          }}
        >
          <div className="modalHeader">
            <div className="modalInfo">
              <div>{props.title}</div>
              <div className="font-bold">{props.itemStatus}</div>
              <div>{props.message}</div>
            </div>
          </div>
          {props.children}
          <div className="buttons_row">
            <div className="btnContainer">
              <Button
                type="primary"
                onButtonClick={() => props.onYesButtonClicked()}
                width="212px"
                height="48px"
                text="Yes"
                margin="0 0 16px 0"
              />
              <Button type="secondary" width="212px" height="48px" text="No" onButtonClick={handelCloseClick} margin="0 0 32px 0" />
            </div>
          </div>
        </div>
      </div>
    </>
    ) : null
  );
};

export default Modal;
