import Button from "../../common/Button/Button";
import Label from "../../common/Label/Label";
import { Link } from "react-router-dom";
import "./summary.scss";

const Summary = (props) => {
  const order = props.data ? props.data : {};
  const linkTo = `/rental-management/order-details/${order.orderId}/businessPartnerId/${order.businessPartnerId}`;
  return (
    <div className="summary_container_rental">
      <div className="title">
        <Label text="Rental Summary" fontSize="20px" bold />
      </div>
      <div className="row">
        <Label text="Rental number" fontSize="13px" />
        <Label text={order.orderId} bold fontSize="16px" />
      </div>
      <div className="row">
        <Label text="Rental period" fontSize="13px" />
        <Label
          text={order.orderDate + " - " + order.endDate}
          bold
          fontSize="16px"
        />
      </div>
      <div className="row">
        <Label text="Total cost" fontSize="13px" />
        <Label text={` ${order && order.totalAmount && order.totalAmount.value}
         ${order && order.totalAmount && order.totalAmount.currency}`} bold fontSize="16px" />
      </div>
      {order && order.businessPartnerId ?
        <div className="button-row">
          <Link to={linkTo}>
            {" "}
            <Button
              text="Go to member page"
              width="100%"
              height="48px"
              type="secondary"
              border="1px solid black"
            />
          </Link>
        </div>
        :
        ""
      }

    </div>
  );
};

export default Summary;
