import "./basket.scss";
import binIcon from "../../../assets/images/bin.svg";
// import IMG from "../Image/IMG";
import { useDispatch, useSelector } from "react-redux";
import { deleteItem } from "../../../features/bag-slice";


const BasketItem = (props) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.shoppingBag.items);

  const handleDeleteItem = (itemId) => {
    dispatch(deleteItem(itemId));
    items.length > 1 ? props.isEmptyBag(false) : props.isEmptyBag(true);
  };

  return (
    <>
      {items && items.length > 0
        ? items.map((item, index) =>

          < div className="basket_container" key={index}>
            < div className="content" >
              <div className="basket_left">
                <img className="basket_img" src={item.rentalPhotos && item.rentalPhotos.preview
                  ? item.rentalPhotos.preview.extraSmall
                  : ""} />
              </div>
              <div className="basket_right">
                <div className="basket_padding_top">
                  {item.articleContent ? item.articleContent[0].productName : ""}</div>
                <div className="basket_padding_top">{item.displayItemId}</div>
                <div className="basket_article_info">
                  <table className="basket_table">
                    <tbody>
                      <tr>
                        <td className="basket_size_lable">Size</td>
                        <td className="basket_size_value">{item.variant.value}</td>
                      </tr>
                      <tr>
                        <td className="basket_price_lable">Price</td>
                        <td className="basket_price_value">{item.price.value} {item.price.currency}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div >
            <img src={binIcon} onClick={() => handleDeleteItem(item.itemId)}></img>
          </div>
        )
        : null}
    </>

  );
};

export default BasketItem;
