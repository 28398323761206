import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../app/root-reducer";
import { IItem } from "../../../model/item";
import "./basket.scss";

interface IBasketPopupProps {
  currentItem: IItem;
}

const BasketPopup = (props: IBasketPopupProps) => {
  const basketItems:IItem[] = useSelector((state: RootState) => state.shoppingBag.items);
  const itemAdded = props.currentItem;

  const linkTo = `/shopping-bag/rental-bag`;
  return (
    <>
      {itemAdded &&
        <div className="popup_container" key={itemAdded.itemId}>
          <div className="popup_top">
            <Link to={linkTo}> <div>Rental bag {basketItems.length}</div>
            </Link>
          </div>
          <div className="content">
            <div className="popup_left">
              <img className="popup_img" src={itemAdded.rentalPhotos && itemAdded.rentalPhotos.preview
                ? itemAdded.rentalPhotos.preview.extraSmall
                : ""} />
            </div>

            <div className="popup_right">
              <div className="popup_padding_top">{itemAdded.articleContent ? itemAdded.articleContent[0].productName : ""}</div>
              <div className="popup_padding_top">{itemAdded.displayItemId}</div>
              <div className="popup_article_info">
                <table className="popup_table">
                  <tbody>
                    <tr>
                      <td className="popup_size_lable">Size</td>
                      <td className="popup_size_value">{itemAdded.variant.value}</td>
                    </tr>

                    <tr>
                      <td className="popup_price_lable">Price</td>
                      <td className="popup_price_value">{itemAdded.price.value} {itemAdded.price.currency}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BasketPopup;
