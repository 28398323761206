import "./mobileHeader.scss";
import LeftArrow from "../../../../assets/images/leftArrow.svg";
import IMG from "../../Image/IMG";
import { useHistory } from "react-router";

const MobileHeader = (props) => {
  const history = useHistory();

  return (
    <div className="header_container">
      <div
        onClick={() => {
          //  return props.onButtonClick(()=> history.goBack());
          if (props.status === "CLOSED") {
            history.push("/rental-management/rental-list");
          } else {
            history.goBack();
          }
        }}
        id={props.key}
        className="arrow_left"
      >
        <IMG src={LeftArrow} />
      </div>
      <div className="page_name"
            style={{
              justifyContent: props.justifyContent,
              padding: props.padding,
              fontSize: props.fontSize
            }}
      >{props.pageName}</div>
      <div className="dummy"></div>
    </div>
  );
};

export default MobileHeader;
