import axios from "axios";
import { getAccessToken } from "./authConfig";
import { getBrandId } from "./Utils/brandUtil";
axios.interceptors.request.use(
  async (config) => {
    let accessToken = null;
    if (config.url.startsWith("/inventoryApi")) {
      await setBrandIdDataToUrl(config);
      accessToken = await getAccessToken(process.env.REACT_APP_RENTAL_INVENTORY_API_SCOPE);
    }
    if (config.url.startsWith("/brandApi")) {
      accessToken = await getAccessToken(process.env.REACT_APP_RENTAL_INVENTORY_API_SCOPE);
    }
    if (config.url.startsWith("/shoppingBagApi") || config.url.startsWith("/rentalManagementApi")) {
      await setBrandIdDataToUrl(config);
      accessToken = await getAccessToken(process.env.REACT_APP_RENTAL_API_SCOPE);
    }
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const setBrandIdDataToUrl = async (config) => {
  const brandId = await getBrandId();
  const url = config.url.replace("{rentalBrandId}", brandId);
  config.url = url;
};
