import "./topRow.scss";
import Button from "../Button/Button";
import Label from "../Label/Label";
import IMG from "../Image/IMG";
import circleIcon from "../../../assets/images/circleIcon.svg";
import warningIcon from "../../../assets/images/warningIcon.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";


const TopRow = (props) => {
  const member = useSelector((state) => state.member);
  const history = useHistory();

  const handleOpenModal = () => {
    if (isMemberExist()) {
      props.show(true);
    } else {
      history.push("/shopping-bag/add-member");
    }
  };

  const isMemberExist = () => member.emailAddress ? true : false;
  const memberNameToDisplay = () => `${member.firstName ? member.firstName : ""}  ${member.lastName? member.lastName[0] + ".": ""}`;

  return (

    <div className="top_row_container" style={isMemberExist() ? { "background": "#8DD5B9" } : { "background": "#FF7777" }}>
      <div className="top_row_left">
        {isMemberExist() ?
          <IMG src={circleIcon} />
          :
          <IMG src={warningIcon} />
        }
        <Label for="name" bold="yes" fontSize="13px" textAlign="left"
          text={isMemberExist() ? memberNameToDisplay() : "Add member to create rental bag"} />
      </div>

      <div className="top_row_right">
        <Button
          id="1"
          onButtonClick={handleOpenModal}
          width="126px"
          height="36px"
          text={isMemberExist() ? "Cancel rental" : "Add member"}
          backgroundColor="transparent"
          color="#222222"
          border="0px"
          fontSize="13px"
          fontWeight="bold"
          textDecoration="underline"
          fontFamily="HM Sans Regular"
          letterSpacing="1.08px"
        />
      </div>
    </div>
  );
};

export default TopRow;
