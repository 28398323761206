import Label from "../Label/Label";
import "./checkBox.scss";


const Checkbox = (props) => {
  const checkBoxHandler = (event) => {
    props.onSelectChange(event.target.value, event.target.checked);
  };

  return (
    <div className="checkBox_container">
      <Label bold fontSize="20px" text="Don’t forget" textAlign="left" padding="16px 25px"></Label>
      <div className="checkbox_middle">
        {props.options.map((item) => (
          <div key={item.id} className="checkBoxGroup">
            <input type="checkbox" id={item.id} value={item.value} onChange={checkBoxHandler} />
            <Label text={item.value} bold fontSize="13px" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkbox;
