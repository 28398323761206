import IMG from "../../common/Image/IMG";
import Label from "../../common/Label/Label";
import "./banner.scss";
import imgURL from "../../../assets/images/tickIcon.svg";

const Banner = (props) => {
  return (
    <div className="banner-container">
      <div>
        {props.status === "CLOSED" ? (
          <>
            <IMG src={imgURL} />
            <Label text="Returned" bold fontSize="20px" />
          </>
        ) : null}
      </div>
      {props.status === "RETURNED" ? (
        <>
          <Label text="Needs inspection" bold fontSize="20px" />
          <Label text="Returned but needs inspection" />
        </>
      ) : null}
    </div>
  );
};

export default Banner;
