import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStore } from "../model/store";


const initialState: { selectedRentalStore: IStore } = {
    selectedRentalStore:
        { storeId: "", countryId: "" }
};

export const storesSlice = createSlice({
    name: "store",
    initialState,
    reducers: {
        storeInfo: (state, { payload }: PayloadAction<IStore>) => {
            state.selectedRentalStore.storeId = payload.storeId;
            state.selectedRentalStore.countryId = payload.countryId;
        },
    }
});

export const { storeInfo } = storesSlice.actions;
export default storesSlice.reducer;
