import React from "react";
import { NoShow } from "../../model/no-show";
import deleteIconBlack from "../../assets/images/TrashBlack.svg";

interface IDisplayNoShow {
    noShows: NoShow[];
    // eslint-disable-next-line no-unused-vars
    deleteReportedSlot(id: number | undefined): void
}
const DisplayNoShow = (props: IDisplayNoShow) => {
    const noShows = props.noShows;
    return <>
        <div className="tableDiv">
            <table>
                <tbody>
                    <tr>
                        <th>Amount</th>
                        <th>Time</th>
                        <th></th>
                    </tr>
                    {
                        noShows && noShows.length > 0 &&
                        noShows.map((slot, index) => {
                            return (
                                <tr key={index}>
                                    <td>{slot.amount}</td>
                                    <td>{slot.bookingTime}</td>
                                    <td>
                                        <img src={deleteIconBlack}
                                            className="deleteAmount" onClick={() => props.deleteReportedSlot(slot.noShowId)}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    </>;
};
export default DisplayNoShow;
