import Button from "../../components/common/Button/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import "./errorScreen.scss";
import Label from "../../components/common/Label/Label";
import errorImg from "../../assets/images/errorImg.svg";
import IMG from "../../components/common/Image/IMG";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

const ErrorScreen = () => {
  const appInsights = useAppInsightsContext();
  const history = useHistory();
  const trackErrorScreenRefresh = useTrackEvent(appInsights, "Refresh button clicked from error page", history.location);
  const handleRefreshButton = () => {
    trackErrorScreenRefresh(history.location);
    history.goBack();
  };
  return (
    <div className="errorScreen_container_rental">
      <div className="row paddingTop">
        <IMG width="50%" src={errorImg} />
      </div>
      <div className="row">
        <Label text="Oh no, something went wrong..." fontSize="16px" bold />
        <Label text="Refresh the page or try again later." fontSize="13px" />
      </div>
      <div className="button-row">
        <Button
          text="Refresh page"
          width="100%"
          height="48px"
          type="primary"
          border="1px solid black"
          onButtonClick={() => {
            handleRefreshButton();
          }}
        />
      </div>
      <div className="row">
        <Label text="OR" fontSize="16px" bold />
      </div>
      <div className="button-row">
        <Link to={"/"}>
          {" "}
          <Button
            text="Go to home page"
            width="100%"
            height="48px"
            type="secondory"
            border="1px solid black"
          />
        </Link>
      </div>
    </div>
  );
};

export default ErrorScreen;
