import { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router";
import Button from "../../../components/common/Button/Button";
import MobileHeader from "../../../components/common/Header/MobileHeader/MobileHeader";
import Label from "../../../components/common/Label/Label";
import Banner from "../../../components/Rental/Banner/Banner";
import Item from "../../../components/Rental/Item/Item";
import Summary from "../../../components/Rental/Summary/Summary";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader/Loader";
import {
  fetchOrderDetails,
  fetchReservations,
} from "../../../services/rm-service";
import "./orderDetails.scss";
import { useSelector } from "react-redux";

function OrderDetails() {
  const params = useParams();
  const history = useHistory();
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);
  const country = useSelector((state) => state.store.selectedRentalStore.countryId);
  const [orderDetails, setOrderDetails] = useState({});
  const [items, setItems] = useState({});
  const linkTo = `/rental-management/order-details/${params.orderId}/return`;
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const fetchItems = useCallback(async () => {
    fetchReservations(params.orderId, storeId)
      .then((res) => {
        setItems(res.data);
        setIsLoading2(false);
      })
      .catch(() => {
        setIsLoading2(false);
        history.push(`/rental-management/error`);
      });
  }, [isLoading2]);

  const fetchOrder = useCallback(async () => {
    fetchOrderDetails(params.orderId, storeId, country)
      .then((res) => {
        setOrderDetails(res.data);
        setIsLoading1(false);
      })
      .catch(() => {
        setIsLoading1(false);
        history.push(`/rental-management/error`);
      });
  }, [isLoading1]);

  useEffect(() => {
    setIsLoading1(true);
    setIsLoading2(true);
    fetchOrder();
    fetchItems();
  }, [storeId]);

  return (
    <div className="order-details-container">
      {isLoading1 || isLoading2 ? <Loader /> : null}
      <MobileHeader status={orderDetails.status} pageName="Details" />
      {orderDetails.status === "RETURNED" ||
        orderDetails.status === "CLOSED" ? (
        <div className="banner">
          <Banner status={orderDetails.status} />
        </div>
      ) : null}
      <div className="rental-summary">
        <Summary data={orderDetails} />
      </div>
      <div className="rental-items">
        <Label text="Items" bold fontSize="20pt" />
        {items && items.length > 0
          ? items.map((item, index) => {
            return (
              <div key={index} className="item-container">
                <Item data={item} order={orderDetails} />
              </div>
            );
          })
          : null}
        <div className="btn-container">
          {orderDetails.status !== "CLOSED" ? (
            <Link to={linkTo}>
              {" "}
              <Button text="Return" width="100%" height="48px" type="primary" />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
