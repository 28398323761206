import axios from "axios";

export function fetchStoreOrders(storeId: string, country: string) {
  return axios({
    url: `/rentalManagementApi/rentals/brand/{rentalBrandId}/country/${country}/store/${storeId}/orders`,
    method: "get"
  });
}

export function fetchStoreOrdersWithFilter(storeId: string, country: string, searchText: string) {
  return axios({
    url: `/rentalManagementApi/rentals/brand/{rentalBrandId}/country/${country}/store/${storeId}/orders`,
    method: "get",
    headers: {
      filter: searchText
    }
  });
}

export function fetchOrderDetails(orderId: string, storeId: string, country: string) {
  return axios({
    url: `/rentalManagementApi/rentals/brand/{rentalBrandId}/country/${country}/store/${storeId}/order/${orderId}`,
    method: "get"
  });
}

export function fetchReservations(orderId: string, storeId: string) {
  return axios({
    url: `/rentalManagementApi/rentals/brand/{rentalBrandId}/store/${storeId}/reservations/order/${orderId}`,
    method: "get"
  });
}

// TODO Jira BTCCCM-1802

export function setOrderReturnStatus(
  storeId: string,
  orderId: string,
  request: Object
) {
  return axios({
    url: `/rentalManagementApi/rentals/brand/{rentalBrandId}/store/${storeId}/order/${orderId}/status`,
    method: "put",
    data: request,
  });
}

export function postOrder(
  request: Object,
  storeId: string
) {
  return axios({
    url: `/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/order`,
    method: "post",
    data: request,
  });
}
export function fetchPeriod(storeId: string) {
  return axios({
    url: `/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/rental-config/period`,
    method: "get"
  });
}
