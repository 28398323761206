import Button from "../../../components/common/Button/Button";
import ItemSummary from "../../../components/ShoppingBag/ItemSummary/ItemSummary";
import "./rentalSummary.scss";
import Label from "../../../components/common/Label/Label";
import Summary from "../../../components/ShoppingBag/Summary/Summary";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { clearMember } from "../../../features/member-slice";
import { clearBag } from "../../../features/bag-slice";
import { clearItemsFromSummery } from "../../../features/item-summery-slice";

function RentalSummary(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handelClick = () => {
    dispatch(clearMember());
    dispatch(clearBag());
    dispatch(clearItemsFromSummery());
    history.push("/shopping-bag/add-rental");
  };
  return (
    <>
      <div className="summary_container_RSB">
        <Label bold fontSize="20px" text="Rental summary" padding="24px 0px"></Label>
        <Summary response={props.saveResponse} endDate={props.endDate} />
        <ItemSummary />
      </div>
      <div className="summary_button">
        <Button background="#FAF9F8" onButtonClick={handelClick}
          text="Create new rental" letterSpacing="1.5px"
          fontWeight="bold" width="100%" height="48px" type="secondary" fontSize="12px" border="1px solid black" />
      </div>
    </>
  );
}

export default RentalSummary;
