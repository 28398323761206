import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Route } from "react-router-dom";
import { UserProvider } from "../../Context/userContext";
import { getAuthProfile } from "../../Utils/authProfileUtil";

export const PrivateRoute = ({ Component, ...props }) => {
  const { instance } = useMsal();
  // eslint-disable-next-line no-unused-vars
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [user, setUser] = useState({});

  const onLoad = async () => {
    const activeAccounts = instance.getAllAccounts();
    const currentAccount = activeAccounts && activeAccounts.length > 0 ? activeAccounts[0] : null;
    const assignedRoles = currentAccount && currentAccount.idTokenClaims["roles"] ? currentAccount.idTokenClaims["roles"] : [];
    const storeRolesData =
      currentAccount && currentAccount.idTokenClaims["extensionAttribute7"] ? currentAccount.idTokenClaims["extensionAttribute7"] : null;
    if (assignedRoles.length > 0) {
      const matchRoleSet = props.roles ? props.roles.filter((role) => assignedRoles.includes(role)) : [];
      if (matchRoleSet.length > 0 || !props.roles || props.roles.length == 0) {
        setIsAuthorized(true);
        setUser(getAuthProfile(assignedRoles, storeRolesData));
      } else {
        setIsAuthorized(false);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance]);

  return (
    <>
      <UserProvider value={user}>
        <Route {...props} render={(routeProps) => <Component {...routeProps} />} />
      </UserProvider>
    </>
  );
};
