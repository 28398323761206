import "./memberShip.scss";
import { useSelector } from "react-redux";
import Label from "../Label/Label";

function MemberShip() {
  const member = useSelector((state) => state.member);

  return (
    <div className="memberShip_container">
      <div className="information">
        {member ? (
          <>
            <Label bold fontSize="16px" text="Member" textAlign="center" padding="0 0 12px 0"></Label>
            <Label fontSize="13px" text={`${member.firstName ? member.firstName : ""} ${member.lastName ? member.lastName : ""}`}
              textAlign="center" padding="4px 0"></Label>
            <Label fontSize="13px" text={member?.emailAddress} textAlign="center" padding="4px 0"></Label>
            <Label fontSize="13px" text="Member ID" textAlign="center" padding="4px 0"></Label>
            <Label fontSize="16px" bold text={member?.customerLoyaltyId} textAlign="center" padding="4px 0"></Label>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default MemberShip;
