import { useEffect, useState } from "react";
import { useParams } from "react-router";
import MobileHeader from "../../../components/common/Header/MobileHeader/MobileHeader";
import Loader from "../../../components/common/Loader/Loader";
import MemberDetails from "../../../components/Rental/MemberDetails/MemberDetails";
import { fetchMemberDetailsById } from "../../../services/member_service";
import { useSelector } from "react-redux";

import "./memberPage.scss";

function MemberPage() {
  const params = useParams();
  const [memberDetails, setMemberDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);
  const country = useSelector((state) => state.store.selectedRentalStore.countryId);

  useEffect(() => {
    const fetchMember = async () => {
      fetchMemberDetailsById(params.businessPartnerId, country, storeId).then((res) => {
        setMemberDetails(res.data);
        setIsLoading(false);
      });
    };
    setIsLoading(true);
    fetchMember();
  }, [params]);
  return (
    <div className="order-details-container">
      {isLoading ? <Loader /> : null}
      <MobileHeader pageName="Member Page" />
      <MemberDetails title="Member information" data={memberDetails} />
    </div>
  );
}

export default MemberPage;
