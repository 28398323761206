import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IItem } from "../model/item";

const initialState: { items: IItem[] } = {
    items: []
};

export const itemSummerySlice = createSlice({
    name: "itemSummery",
    initialState,
    reducers: {
        addItemsForSummery: (state, { payload }: PayloadAction<IItem[]>) => {
            state.items = payload;
        },
        clearItemsFromSummery: (state) => {
            state.items = [];
        }
    }
});

export const { addItemsForSummery, clearItemsFromSummery } = itemSummerySlice.actions;
export default itemSummerySlice.reducer;
