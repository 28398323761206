import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/root-reducer";
import { IItem } from "../../../model/item";
import Button from "../Button/Button";
import "./card.scss";


// eslint-disable-next-line no-unused-vars
interface ICardProps {
  dataToDisplay: IItem
  buttonText: string;
  isItemAddedToBag?: boolean;
  children?: ReactNode;
  // eslint-disable-next-line no-unused-vars
  onButtonClicked(item: IItem): void;
}


const Card = (props: ICardProps) => {
  const member = useSelector((state: RootState) => state.member);
  const addedBaggedItems = useSelector((state: RootState) => state.shoppingBag.items);
  const [isAdded, setIsAdded] = useState(false);

  const data = props.dataToDisplay;


  useEffect(() => {
    compareItems();
  }, [addedBaggedItems.length]);

  const compareItems = () => {
    if (addedBaggedItems.find((item) => item.itemId === data.itemId)) {
      setIsAdded(true);
    }
  };

  const handleAddToBag = () => {
    props.onButtonClicked(data);
  };

  return (
    <div className="card_container" key={data.itemId}>
      <div className="card_top">
        <div className="card_left">
          <img className="card_img" src={data.rentalPhotos && data.rentalPhotos.preview
            ? data.rentalPhotos.preview.extraSmall
            : ""} />
        </div>
        <div className="card_right" style={member && member.emailAddress ? { justifyContent: "space-around" } : { justifyContent: "flex-start" }}>
          <div className="productName">{data.articleContent ? data.articleContent[0].productName : ""}</div>
          <div className="info">{data.articleContent
            ? data.articleContent[0].productCollection
            : ""}</div>
          <div className="info">{data.displayItemId}</div>

          {data.status && data.status.value !== "READY FOR RENTAL" ?
            <div className="red">{data.status ? data.status.label[0].value : ""}</div>
            :
            <div className="info">{data.status ? data.status.label[0].value : ""}</div>
          }
          <div className="info">{data.price.value} {data.price.currency}</div>
          <div className="info">{data.variant && data.variant.value}</div>
          {member && member.emailAddress &&
            <div className="card_buttom">
              {isAdded ?
                (<Button
                  text={props.buttonText}
                  type="disable"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  color="#707070"
                  border="none"
                  backgroundColor="#D0D0D0"
                  fontFamily="HM Sans Regular"
                  letterSpacing="1.08px"
                />)
                :
                (<Button
                  onButtonClick={handleAddToBag}
                  text={props.buttonText}
                  type="primary"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  color="#FFFFFF"
                  border="none"
                  backgroundColor="#222222"
                  fontFamily="HM Sans Regular"
                  letterSpacing="1.08px"
                />)}
            </div>
          }
        </div>

      </div>
    </div>
  );
};

export default Card;
