import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IItem } from "../model/item";

const initialState: { items: IItem[], currentApp: String } = {
  items: [],
  currentApp: "Shopping bag",
};

export const bagSlice = createSlice({
  name: "shoppingBag",
  initialState,
  reducers: {
    addItemToBag: (state, { payload }: PayloadAction<IItem>) => {
      state.items.push(payload);
    },
    deleteItem: (state, { payload }: PayloadAction<number>) => {
      const index = state.items.findIndex((item) => item.itemId === payload);
      if (index > -1) {
        state.items.splice(index, 1);
      }
    },
    clearBag: (state) => {
      state.items = [];
    },
    setCurrentApp: (state, { payload }: PayloadAction<string>) => {
      state.currentApp = payload;
    },
  },
});

export const { addItemToBag, deleteItem, clearBag, setCurrentApp } =
  bagSlice.actions;
export default bagSlice.reducer;
