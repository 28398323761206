import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Row from "../../../components/common/Row/Row";
import Label from "../../../components/common/Label/Label";
import Loader from "../../../components/common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentApp } from "../../../features/bag-slice";
import {
  fetchStoreOrders,
  fetchStoreOrdersWithFilter,
} from "../../../services/rm-service";
import "./rentalList.scss";
import SearchField from "../../../components/common/SearchFiled/SearchField";

function RentalList() {
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);
  const country = useSelector((state) => state.store.selectedRentalStore.countryId);
  const [searchInputText, setSearchInputText] = useState("");
  const [orders, setOrders] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [storeIdChange, setStoreIdChange] = useState(false);
  const [newStoreId, setnewStoreId] = useState("");

  useEffect(() => {
    if (newStoreId == storeId) {
      setStoreIdChange(false);
    } else {
      setnewStoreId(storeId);
      setStoreIdChange(true);
    }
  }, [storeId]);

  useEffect(() => {
    if (storeIdChange) {
      setSearchInputText("");
      if (orders) {
        setSearchInputText("");
        setOrders({});
      }
    }
  }, [storeId, storeIdChange]);

  useEffect(() => { }, [storeId]);


  const searchOrder = useCallback(
    async (text, storeId, country) => {
      await fetchStoreOrdersWithFilter(storeId, country, text)
        .then((res) => {
          setOrders(res.data.orders);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/rental-management/error");
        });
    },
    [searchInputText, isLoading]
  );

  const fetchOrder = useCallback(async (storeId, country) => {
    await fetchStoreOrders(storeId, country)
      .then((res) => {
        setOrders(res.data.orders);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        history.push("/rental-management/error");
      });
  }, [searchInputText]);

  useEffect(() => {
    dispatch(setCurrentApp("Rental"));
    setIsLoading(true);
    if (storeId && country) {
      fetchOrder(storeId, country);
    }
  }, [storeId]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchInputText.length > 0 && storeId && country) {
        setIsLoading(true);
        searchOrder(searchInputText, storeId, country);
      }
    }
  };
  const handleClear = (value) => {
    handleSearchTextChnage(value);
    setIsLoading(true);
    if (storeId && country) {
      fetchOrder(storeId, country);
    }
  };
  const handleSearchTextChnage = (text) => {
    setSearchInputText(text);
  };
  return (
    <div className="rental-list-container">
      {isLoading ? <Loader /> : null}
      <div className="top_title">
        <Label text="Rental management" bold fontSize="28px" />
      </div>
      <div className="search-field">
        <SearchField
          type="text"
          name="search"
          width="100%"
          value={searchInputText}
          onChange={(value) => {
            handleSearchTextChnage(value);
          }}
          onClearClick={(value) => {
            handleClear(value);
          }}
          onKeyPress={(event) => handleKeyPress(event)}
          placeholder="Search for rental no. or member email"
        />
      </div>
      {orders && orders.RETURNED && orders.RETURNED.length > 0 ? (
        <div className="inspection-list-container">
          <div className="list-title">
            <Label text="Needs inspection" bold fontSize="20px" />
          </div>
          {orders.RETURNED.map((item) => {
            return (
              <Row
                key={item.orderId}
                title={item.orderId}
                subtitle={item.orderDate + " - " + item.endDate}
                bg="#F4E6E3"
              />
            );
          })}
        </div>
      ) : null}
      {orders && orders.OUT_FOR_RENT && orders.OUT_FOR_RENT.length > 0 ? (
        <div className="outForRent-list-container">
          <div className="list-title">
            <Label text="Out for rent" bold fontSize="20px" />
          </div>
          {orders.OUT_FOR_RENT.map((item) => {
            return (
              <Row
                key={item.orderId}
                title={item.orderId}
                subtitle={item.orderDate + " - " + item.endDate}
              />
            );
          })}
        </div>
      ) : null}
      {orders && orders.CLOSED && orders.CLOSED.length > 0 ? (
        <div className="outForRent-list-container">
          <div className="list-title">
            <Label text="Closed" bold fontSize="20px" />
          </div>
          {orders.CLOSED.map((item) => {
            return (
              <Row
                key={item.orderId}
                title={item.orderId}
                subtitle={item.orderDate + " - " + item.endDate}
                bg="#E4E4E4"
              />
            );
          })}
        </div>
      ) : null}
      {orders &&
        (orders.RETURNED || orders.OUT_FOR_RENT || orders.CLOSED) ? null : (
        <div className="no-orders">No rentals found</div>
      )}
    </div>
  );
}

export default RentalList;
