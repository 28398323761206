import "./rentalBag.scss";
import { useEffect, useRef, useState } from "react";
import BasketItem from "../../../components/common/Basket/BasketItem";
import Label from "../../../components/common/Label/Label";
import CommonDropdown from "../../../components/common/Dropdown/CommonDropdown";
import MemberShip from "../../../components/common/MemberShip/MemberShip";
import CheckBox from "../../../components/common/CheckBox/CheckBox";
import { useSelector } from "react-redux";
import Button from "../../../components/common/Button/Button";
import { postOrder, fetchPeriod } from "../../../services/rm-service";
import RentalSummary from "../../../Pages/ShoppingBag/RentalSummary/RentalSummary";
import { useHistory } from "react-router";
import { clearBag } from "../../../features/bag-slice";
import { useDispatch } from "react-redux";
import { addItemsForSummery, clearItemsFromSummery } from "../../../features/item-summery-slice";
import moment from "moment";
import Loader from "../../../components/common/Loader/Loader";
import { getCheckBoxes, getDropdownOptionsFromSameKeyValue } from "../../../Utils/app-utils";
// TO DO Jira BTCCCM-1801

import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

function RentalBag() {
  const appInsights = useAppInsightsContext();
  const [selectedDay, setSelectedDay] = useState({});
  const [daysOption, setDaysOption] = useState([]);
  const member = useSelector((state) => state.member);
  const items = useSelector((state) => state.shoppingBag.items);
  const [order, setorder] = useState(undefined);
  const [rentalConfirmed, setRentalConfirmed] = useState(false);
  const [isEmptyBasket, setIsEmptyBasket] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [daysIsEmpty, setDaysIsEmpty] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [isCallingApiInProgress, setIsCallingApiInProgress] = useState(false);
  const errorRef = useRef({});
  const scrollToRef = ((ref) => window.scrollTo(ref));
  const executeScroll = () => scrollToRef(errorRef);
  const dispatch = useDispatch();
  const itemsInBasket = useSelector((state) => state.shoppingBag.items);
  const [currency, setCurrency] = useState("");
  const history = useHistory();
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);
  const dropdownProps = { boxWidth: "93%" };
  const trackOrder = useTrackEvent(appInsights, "Order", order);
  const trackRentalConfrimed = useTrackEvent(appInsights, "Rental Confrimed", rentalConfirmed);
  useEffect(() => {
    getDaysData();
  }, []);

  const getDaysData = () => {
    let dropdownOptions = [];
    if (storeId) {
      fetchPeriod(storeId)
        .then((res) => {
          if (res.data.slots) {
            dropdownOptions = getDropdownOptionsFromSameKeyValue(res.data.slots);
            if (dropdownOptions) {
              setDaysOption(dropdownOptions);
            }
          }
        }).catch(() => {
          history.push("/shopping-bag/error");
        });
    }
  };


  useEffect(() => {
    if (itemsInBasket.length === 0) {
      setIsEmptyBasket(true);
    }
    selectedDay && selectedDay.value && computeTotalAmount(selectedDay);
  }, [itemsInBasket, daysIsEmpty]);

  const handleChange = (selectedDayFromDropDown) => {
    setSelectedDay(selectedDayFromDropDown);
    computeTotalAmount(selectedDayFromDropDown);
    const rentalEndDate = moment(moment().add(selectedDayFromDropDown.value, "days")).format("DD MMM YYYY");
    setEndDate(rentalEndDate);
  };

  const computeTotalAmount = (days) => {
    let totalCost = 0;
    if (days) {
      const prices = itemsInBasket && itemsInBasket.map((i) => {
        setCurrency(i.price.currency);
        return +i.price.value;
      });
      const usedCurrency = itemsInBasket && itemsInBasket.map((i) => {
        return i.price.currency;
      });
      const sum = prices.reduce((a, b) => a + b, 0);
      totalCost = sum * (days.value / daysOption[0].id);
      setCurrency(usedCurrency[0]);
    }
    setTotalCost(totalCost);
  };

  const handleAddRental = async () => {
    if (selectedDay.value) {
      const requestItemData = buildRequest();
      setIsCallingApiInProgress(true);
      const res = await postOrder(requestItemData, storeId);
      setIsCallingApiInProgress(false);
      if (res) {
        setorder(res.data.order);
        trackOrder(res.data.order);
        trackRentalConfrimed(true);
        setRentalConfirmed(true);
        dispatch(addItemsForSummery(items));
        dispatch(clearBag());
        setDaysIsEmpty(false);
      }
    } else {
      executeScroll();
      setDaysIsEmpty(true);
    }
  };

  const buildRequest = () => {
    const reservations = items.map((item) => ({
      itemId: item.itemId,
      numberOfDays: Object.assign({}, selectedDay).value,
      price: {
        tier: item.price.tierId,
        value: item.price.value,
        currency: item.price.currency,
        total: item.price.value * (selectedDay.value / daysOption[0].id)
      }
    }));
    const totalPrice = {
      currency: items && items[0].price.currency,
      value: totalCost
    };
    return {
      businessPartnerId: member.businessPartnerId,
      customerLoyaltyId: member.customerLoyaltyId,
      reservations:
        reservations,
      storeId: storeId,
      totalPrice
    };
  };

  const handleAddMoreRedirection = () => {
    history.push("/shopping-bag/add-rental");
    dispatch(clearItemsFromSummery());
  };


  const handelEmptyBag = (value) => {
    setIsEmpty(value);
  };


  return <>
    <div className="rental_bag_container">
      <div className="rental_bag_top_row">
        <Label text={order ? "Rental created" : "Rental bag"} fontSize="28px" bold margin="0 auto" />
      </div>

      {!isEmptyBasket && order === undefined ? (<>
        <div className="renta_bag_content">
          <div className="info">
            <BasketItem isEmptyBag={(v) => handelEmptyBag(v)} />
          </div>
          {daysOption.length > 0 &&
            <div className="days">
              <CommonDropdown
                defaultValue="Select days"
                options={daysOption}
                selectedItem={selectedDay}
                onItemSelect={(selectedDayFromDropDown) => handleChange(selectedDayFromDropDown)}
                daysIsEmpty={daysIsEmpty}
                errorMsg="You need to select amount of days"
                ref={errorRef}
                additionalText="days"
                dropdownProps={dropdownProps} />
            </div>
          }
          <div>
            <Button
              onButtonClick={() => handleAddMoreRedirection()}
              text="Add more items"
              type="secondary"
              width="100%"
              height="48px"
              fontWeight="bold"
              margin="30px 0 0 0"
              fontSize="13px"
              letterSpacing="1px"
            />
          </div>
        </div>
        <MemberShip />
        <CheckBox
          title="Click"
          width="100%"
          height="47px"
          options={getCheckBoxes()}
          onSelectChange={() => { }}

        />

        <div className="total">
          <Label bold fontSize="16px" text="Total"></Label>
          <Label bold fontSize="16px" text={`Amount  ${totalCost} ${currency}`}></Label>
        </div>
        <div className="checkbox_buttom">
          <Button
            onButtonClick={(event) => handleAddRental(event)}
            text="Confirm rental"
            type="primary"
            width="100%"
            height="48px"
            fontSize="12px"
            color="#FFFFFF"
            border="none"
            backgroundColor="#222222"
            letterSpacing="1.5px"
            disabled={isCallingApiInProgress}
          />
        </div>
        {isCallingApiInProgress && <Loader></Loader>}
      </>) : ""
      }

    </div>

    {isEmptyBasket && isEmpty && !rentalConfirmed &&
      <div className="empty_bag">
        <Label text="The rental bag is empty" fontSize="24px" bold margin="5 auto" padding="48px" />
        <Button
          onButtonClick={() => history.push("add-rental")}
          text="Search for items"
          type="primary"
          width="100%"
          height="48px"
          fontSize="12px"
          color="#FFFFFF"
          border="none"
          backgroundColor="#222222"
          margin="0 0 48px 0"
        />
      </div>}

    {rentalConfirmed &&
      order && (<>
        <RentalSummary saveResponse={order} endDate={endDate}></RentalSummary>
      </>)
    }

  </>;
}

export default RentalBag;
