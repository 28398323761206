/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMember } from "../model/member";

const initialState: IMember = {};
export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    storeMember: (state, { payload }: PayloadAction<IMember>) => {
      state.businessPartnerId = payload.businessPartnerId;
      state.customerLoyaltyId = payload.customerLoyaltyId;
      state.emailAddress = payload.emailAddress;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
    },
    clearMember: (state) => {
      return (state = {});
    }
  }
});

export const { storeMember, clearMember } = memberSlice.actions;
export default memberSlice.reducer;
