import { useSelector } from "react-redux";
import { RootState } from "../../../app/root-reducer";
import { IItem } from "../../../model/item";
import "./itemSummary.scss";


const ItemSummary = () => {
  const items: IItem[] = useSelector((state: RootState) => state.summery.items);

  return (
   items && items.map((item) => {
      return (<>
        <div className="item_summary_container" key={item.itemId}>
          <div className="content">
            <div className="item_summary_left">
              <img className="item_summary_img" src={item.rentalPhotos?.preview?.extraSmall ?? ""} />
            </div>

            <div className="item_summary_right">
              <div className="item_summary_padding_top">{item.articleContent ? item.articleContent[0].productName : ""}</div>
              <div className="item_summary_padding_top">{item.displayItemId}</div>
              <div className="item_summary_article_info">
                <table className="item_summary_table">
                  <tbody>
                    <tr>
                      <td className="item_summary_size_lable">Size</td>
                      <td className="item_summary_size_value">{item.variant.value}</td>
                    </tr>

                    <tr>
                      <td className="item_summary_price_lable">Price</td>
                      <td className="item_summary_price_value">{item.price.value} {item.price.currency}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>);
    })

  );
};

export default ItemSummary;
