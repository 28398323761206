/* eslint-disable no-prototype-builtins */
import React, { Component } from "react";
import "./dropdown.scss";

class CommonDropdown extends Component {
  constructor(props) {
    super(props);
    // Creating a reference
    this.box = React.createRef();
  }
  state = {
    items: this.props.options || [],
    showItems: false,
  };

  componentDidMount() {
    // Adding a click event listener
    document.addEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (this.box && !this.box.current?.contains(event.target)) {
      this.setState({
        showItems: false,
      });
    }
  };

  dropDown = () => {
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };

  selectItem = (item) => {
    this.setState({
      selectedItem: item,
      showItems: false,
    });
    this.props.onItemSelect(item);
  };

  render() {
    return (
      <div className="selectBox">
        <div className="selectboxContainer" style={{
          background: this.props.dropdownProps.background,
          border: this.props.dropdownProps.border,
          position: this.props.dropdownProps.selectBoxPosition,
          left: this.props.dropdownProps.selectBoxLeft,
          top: this.props.dropdownProps.selectBoxTop
        }}
          ref={this.box}>
          <div
            className={
              Object.keys(this.props.selectedItem).length > 0
                ? "selectedItem selected"
                : "selectedItem"
            }
            style={{
              width: this.props.dropdownProps.spaceToArrow
            }}
            onClick={this.dropDown}
          >
            {Object.keys(this.props.selectedItem).length > 0 ?
              this.props.additionalText ?
                `${this.props.selectedItem.value} ${this.props.additionalText} ` : this.props.selectedItem.value
              : this.props.defaultValue}
          </div>
          <div className="selectboxArrow" onClick={this.dropDown} style={{
            height: this.props.dropdownProps.selectedBoxheight
          }}>
            <span
              className={`${this.state.showItems ? "selectboxArrowUp" : "selectboxArrowDown"
                }`}
            />
          </div>
        </div>

        <div
          style={{
            display: this.state.showItems ? "block" : "none",
            width: this.props.dropdownProps.boxWidth,
            border: this.props.dropdownProps.selectedItemsBorder,
            left: this.props.dropdownProps.itemsLeft,
            top: this.props.dropdownProps.itemsTop

          }}
          className="selectboxItems"
          onBlur={this.dropDown}
        >
          {this.state.items &&
            this.state.items.map((item) => (
              <div
                key={item.id}
                onClick={() => this.selectItem(item)}
                className={
                  this.props.selectedItem.id === item.id
                    ? "seletedOption"
                    : "option"
                }
              >
                {item.value} {this.props.additionalText && this.props.additionalText}
              </div>
            ))}
        </div>
        <div className="error">
          {this.props.daysIsEmpty ?
            this.props.errorMsg
            :
            ""
          }
        </div>
      </div>
    );
  }
}

export default CommonDropdown;
