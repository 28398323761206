import { combineReducers } from "@reduxjs/toolkit";
import memberReducer from "../features/member-slice";
import rentalBagReducer from "../features/bag-slice";
import itemSummeryReducer from "../features/item-summery-slice";
import storeInfoReducer from "../features/stores-slice";


export const rootReducer = combineReducers({
    member: memberReducer,
    shoppingBag: rentalBagReducer,
    summery: itemSummeryReducer,
    store: storeInfoReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
