import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../app/root-reducer";
import BasketPopup from "../../../components/common/Basket/BasketPopup";
import Card from "../../../components/common/Card/Card";
import Label from "../../../components/common/Label/Label";
import Loader from "../../../components/common/Loader/Loader";
import Modal from "../../../components/common/Modal/Modal";
import TopRow from "../../../components/common/TopRow/TopRow";
import Info from "../../../components/ShoppingBag/Info/Info";
import NoShowInfo from "../../../components/ShoppingBag/NoShowInfo/NoShowInfo";
import { addItemToBag, clearBag } from "../../../features/bag-slice";
import { clearMember } from "../../../features/member-slice";
import { IItem } from "../../../model/item";
import { IResponseEntity } from "../../../model/response-entity";
import { getArticleItemsByArticleId, getArticleItemsByItemId } from "../../../services/inventory-service";
import "./addRental.scss";
import SearchBar from "./SearchBar";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

const AddRental = () => {
  const appInsights = useAppInsightsContext();
  const history = useHistory();
  const [searchInputText, setSearchInputText] = useState<string>("");
  const [chosenTab, setChosenTab] = useState<string>("Article number");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [statusShowModal, setStatusShowModal] = useState(false);
  const [items, setItems] = useState<IItem[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [showItemAddedToBagPopup, setItemAddedToBagShowpoup] = useState(false);
  const [currentItemSelected, setCurrentItemSelected] = useState<IItem>();
  const [showInfo, setShowInfo] = useState(true);
  const dispatch = useDispatch();
  const itemsOnBag = useSelector((state: RootState) => state.shoppingBag.items);
  const storeId = useSelector((state: RootState) => state.store.selectedRentalStore.storeId);
  const trackSearchItem = useTrackEvent(appInsights, "search item", {searchInputText});
  const trackCancelAfterAddingToBag = useTrackEvent(appInsights, "Cancel after adding to bag", itemsOnBag);
  const trackAddItemToBag = useTrackEvent(appInsights, "Item added to bag", currentItemSelected );

  useEffect(() => {
    handleClearClick();
    handelShowInfo();
  }, [storeId, chosenTab]);

  useEffect(() => {
    handleAddItemToTheRentalBag();
  }, [currentItemSelected]);

  const handleCloseCancelRentalModal = () => {
    setShowCancelModal(false);
  };

  const handleCloseItemStatusWarningModal = () => {
    setStatusShowModal(false);
    setCurrentItemSelected(undefined);
  };

  const getItemsByArticleId = useCallback(
    async (articleId, storeId) => {
      setLoading(true);
      await getArticleItemsByArticleId(storeId, articleId)
        .then((res: IResponseEntity<IItem[]>) => {
          handelResults(res);
        }).catch(() => {
          handelError();
        });
    },
    []
  );

  const getItemsByItemId = useCallback(
    async (itemNumber, storeId) => {
      setLoading(true);
      await getArticleItemsByItemId(storeId, itemNumber)
        .then((res: IResponseEntity<IItem[]>) => {
          handelResults(res);
        }).catch(() => {
          handelError();
        });
    },
    []
  );

  const handelResults = (res: IResponseEntity<IItem[]>) => {
    const rentalItems = res.data;
    setItems(rentalItems);
    setLoading(false);
    setShowInfo(false);
  };

  const handelError = () => {
    setLoading(false);
    history.push("/shopping-bag/error");
  };

  const handleSearch = (value: string) => {
    trackSearchItem({searchInputText: value});
    setSearchInputText(value);
  };

  const selectedTab = (tab: string) => {
    setChosenTab(tab);
  };

  const handleCancelRentalAndStartNewSearch = () => {
    trackCancelAfterAddingToBag(itemsOnBag);
    dispatch(clearMember());
    dispatch(clearBag());
    handleCloseCancelRentalModal();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && searchInputText && searchInputText.length > 0 && storeId) {
      chosenTab && chosenTab === "Article number" ?
        getItemsByArticleId(searchInputText.trim(), storeId)
        :
        getItemsByItemId(searchInputText.trim(), storeId);
    } else if (event.key === "Enter") {
      setShowInfo(true);
      setItems([]);
      return;
    }
  };

  const handleClearClick = () => {
    setSearchInputText("");
    if (items && items.length > 0) {
      handelShowInfo();
      setItems([]);
    }
  };

  const handelShowInfo = () => {
    setShowInfo(true);
  };

  const hideAddToBagPopup = () => {
    setTimeout(() => {
      setItemAddedToBagShowpoup(false);
    }, 2000);
  };

  const handleAddItemToTheRentalBag = () => {
    if (currentItemSelected) {
      if (currentItemSelected.status && currentItemSelected.status.value !== "READY FOR RENTAL") {
        setStatusShowModal(true);
      } else {
        addItemToBagAndShowBasketPopup();
      }
    }
  };

  const handleIfStatusIsOk = () => {
    addItemToBagAndShowBasketPopup();
  };

  const addItemToBagAndShowBasketPopup = () => {
    if (currentItemSelected) {
      trackAddItemToBag(currentItemSelected);
      dispatch(addItemToBag(currentItemSelected));
      if (statusShowModal) {
        setStatusShowModal(false);
      }
      setItemAddedToBagShowpoup(true);
      hideAddToBagPopup();
    }
  };

  const handleAddToCartButtonClicked = (itemToBeAddedToBag: IItem) => {
    // check if item is on basket , then return
    // check if status is correct , if not show status popup
    // if above are correct show basket popup and close in 2 sec
    // add item to the bag by dispatching action
    // update the item number to show on bag icon

    if (isItemNotExistOnBag(itemsOnBag, itemToBeAddedToBag)) {
      setCurrentItemSelected(itemToBeAddedToBag);
    } else {
      return;
    }
  };
  return (
    <div className={statusShowModal ? "add-rental_container maxHeight" : "add-rental_container"}>
      <div className="member_row">
        <TopRow show={(isShow: boolean) => setShowCancelModal(isShow)}></TopRow>
      </div>
      {showItemAddedToBagPopup && currentItemSelected && (<BasketPopup currentItem={currentItemSelected} />)}

      {statusShowModal ? (
        <Modal
          show={statusShowModal}
          onNoButtonClicked={() => handleCloseItemStatusWarningModal()}
          title="This item is marked"
          itemStatus={`${currentItemSelected && currentItemSelected.status &&
            currentItemSelected.status.label && currentItemSelected.status.label[0].value}`}
          message="Are you sure you want to rent it out?"
          onYesButtonClicked={() => handleIfStatusIsOk()}
        />
      ) : ""}

      {showCancelModal ? (
        <Modal
          show={showCancelModal}
          onNoButtonClicked={() => handleCloseCancelRentalModal()}
          onYesButtonClicked={() => handleCancelRentalAndStartNewSearch()}
          message="Are you sure you want to cancel the rental and search for a new member?"
        />
      ) : null}

      <div className="add_rental_top_row">
        <div className="add_rental_title">
          <Label text="Search for items" bold fontSize="28px" fontWeight="bold" />
        </div>

        <SearchBar
          onChange={(value: string) => {
            handleSearch(value);
          }}
          onKeyPress={(event: any) => handleKeyPress(event)}
          onClearClick={handleClearClick}
          value={searchInputText}
          activeTab={(tab: string) =>
            selectedTab(tab)
          }
          showInfo={handelShowInfo}
        />

      </div>
      {loading ? (<>
        <Loader></Loader>
      </>) : ""}

      {!loading &&
        <>
          {showInfo &&
            <>
              <Info />
              <NoShowInfo buttonText="Tell us" />
            </>
          }
          {!showInfo && items && items.length === 0 &&
            <>
              <div className="error_msg">No Items found </div>
              <NoShowInfo buttonText="Tell us" />
            </>
          }
        </>
      }

      {!loading && items && items.length > 0 && items.map((item, index) => {
        return (
          <div className="card_containers" key={index}>
            <Card dataToDisplay={item} buttonText="Add"
              onButtonClicked={(item: IItem) => handleAddToCartButtonClicked(item)}> </Card>
          </div>
        );
      }
      )}

    </div>
  );
};

export default AddRental;

function isItemNotExistOnBag(itemsOnBag: IItem[], itemToBeAddedToBag: IItem) {
  const itemFound = itemsOnBag.find((i) => i.itemId === itemToBeAddedToBag.itemId);
  return itemFound ? false : true;
}

