import "./label.scss";

const Label = (props) => {
  return (
    <label
      htmlFor={props.for}
      style={{
        color: props.error ? "#E02020" : "#222222",
        fontWeight: props.bold ? "bold" : "normal",
        fontSize: props.fontSize,
        padding: props.padding,
        textAlign: props.textAlign,
        margin: props.margin,
      }}
    >
      {props.text}
    </label>
  );
};

export default Label;
