import BackDrop from "../BackDrop/BackDrop";
import "./loader.scss";

const Loader = () => {
  return (
    <>
      <BackDrop />
      <div className="container">
        <div className="loader"></div>
      </div>
    </>
  );
};

export default Loader;
