/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../app/root-reducer";
import Button from "../../../components/common/Button/Button";
import Email from "../../../components/common/Email/Email";
import Label from "../../../components/common/Label/Label";
import MobileHeader from "../../../components/common/Header/MobileHeader/MobileHeader";
import TextArea from "../../../components/common/TextArea/TextArea";
import { clearMember, storeMember } from "../../../features/member-slice";
import { IMember } from "../../../model/member";
import { fetchMemberDetails } from "../../../services/member_service";
import "./memberId.scss";

function MemberId() {
  const [error, setError] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isExisting, setIsExisting] = useState("");
  const [member, setMember] = useState<IMember>({});
  const history = useHistory();
  const storeId = useSelector((state: RootState) => state.store.selectedRentalStore.storeId);
  const country = useSelector((state: RootState) => state.store.selectedRentalStore.countryId);
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (email) => {
      if (isSending) return;
      setIsSending(true);
      dispatch(clearMember());
      await fetchMemberDetails(email, country, storeId)
        .then((res) => {
          setMember(res.data);
          // setError(true);
          setIsExisting("yes");
        })
        .catch((err) => setIsExisting(err));
      setIsSending(false);
      setError(false);
    },
    [selectedEmail]
  );

  const handleMemberEmail = (emailEntered: string) => {
    setSelectedEmail(emailEntered);
  };
  const handleClick = () => {
    fetchData(selectedEmail);
  };

  const handleCreateRentalRedirection = () => {
    dispatch(storeMember(member));
    history.push("/shopping-bag/add-rental");
  };

  return (
    <div className="member_id_container">
      <MobileHeader pageName="Add member" />
      <div className="search_inputBox">
        <div className="search_top_row">
          <TextArea
            label="Search for member"
            labelFontSize="20px"
            bold="bold"
            margin="23px 0 8px 0"
            value="In order to create a rental you need to add the customers membership "
            readonly
          ></TextArea>
        </div>
        <Email
          name="email"
          onChange={(value: string) => handleMemberEmail(value)}
          isValid={(value: boolean) => {
            setError(value);
          }}
          display={error ? "none" : "block"}
          existing={isExisting}
          placeholder="Enter members Email"
        />
        {error != null && error ? (
          <Button
            width="90%"
            height="48px"
            backgroundColor="#222222"
            text="Search"
            color="#FFFFFF"
            border="0px"
            fontSize="14px"
            onButtonClick={handleClick}
            isDisabled={isSending}
            fontFamily="HM Sans Regular"
            letterSpacing="1.08px"
          />
        ) : (
          <Button width="90%" height="48px" backgroundColor="#D0D0D0" text="Search"
            color="#707070" border="0px" letterSpacing="1.08px" fontFamily="HM Sans Regular" fontSize="14px" />
        )}
      </div>
      <div className="member_information">
        {member.emailAddress ? (
          <>
            <Label bold fontSize="20px" text="Membership account" textAlign="center" padding="0 0 35px 0"></Label>
            <Label bold fontSize="16px" text={`${member.firstName ? member.firstName : ""} ${member.lastName ? member.lastName : ""}`}
              textAlign="center" padding="0 0 9px 0"></Label>
            <Label fontSize="13px" text={member?.emailAddress} textAlign="center" padding="0 0 4px 0"></Label>
            <Button
              width="90%"
              height="48px"
              backgroundColor="#222222"
              text="Add member"
              color="#FFFFFF"
              border="0px"
              fontSize="14px"
              margin="21px 0px 15px 0px"
              onButtonClick={handleCreateRentalRedirection}
              fontFamily="HM Sans"
            />
          </>
        ) : (
          ""
        )}
        {isExisting != "" && isExisting != "yes" ? (
          <>
            <Label bold="yes" fontSize="20px" text="Membership account" textAlign="center" padding="0 0 35px 0"></Label>
            <Label bold="yes" fontSize="16px" text="No member found" textAlign="center" padding="0 0 26px 0" error></Label>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default MemberId;
