import "./sideDrawerButton.scss";

const SideDrawerButton = (props) => {
  return (
    <button className="toggeleButton" onClick={props.click}>
      <div className="toggeleButtonLine" />
      <div className="toggeleButtonLine" />
      <div className="toggeleButtonLine" />
    </button>
  );
};

export default SideDrawerButton;
