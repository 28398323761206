import "./textArea.scss";
import Label from "../Label/Label";

const TextArea = (props) => {
  return (
    <div className="textFieldContainer">
      <Label text={props.label} fontSize={props.labelFontSize} bold={props.bold} margin={props.margin} />
      <textarea
        className="textarea"
        style={{
          fontWeight: props.fontWeight ? "bold" : "normal",
          width: props.width,
          height: props.height,
          fontSize: props.fontSize,
          padding: props.padding
        }}
        value={props.value ? props.value : null}
        disabled="yes"
      ></textarea>
    </div>
  );
};

export default TextArea;
