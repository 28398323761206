export const ROLE_ASSORTMENT_WRITE = "RentalAssortment.Create";
export const ROLE_ASSORTMENT_READ = "RentalAssortment.Read";
export const ROLE_INVENTORY_ADMIN = "RentalInventory.Create";
export const ROLE_INVENTORY_READ = "RentalInventory.Read";

export const STORE_MANAGER_ROLE = "STORE_MANAGER";
export const DEPARTMENT_MANAGER_ROLE = "DEPARTMENT_MANAGER";
export const CASH_OFFICE_RESPONSIBLE_ROLE = "CASH_OFFICE_RESPONSIBLE";
export const VISUAL_MERCHANDISER_ROLE = "VISUAL_MERCHANDISER";
export const SALES_ADVISOR_ROLE = "SALES_ADVISOR";
