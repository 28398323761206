import { useState, useEffect } from "react";
import "./sideDrawer.scss";
import userIcon from "../../../assets/images/user.svg";
import rightArrow from "../../../assets/images/rightArrow.svg";
import whiteClose from "../../../assets/images/whiteClose.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentApp } from "../../../features/bag-slice";
import { useHistory } from "react-router";
import { msalInstance } from "../../../authConfig";

const SideDrawer = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentApp = useSelector((state) => state.shoppingBag.currentApp);
  useEffect(() => {
    props.show ? setShowMenu(true) : setShowMenu(false);
  }, [props.show]);
  const handleNav = (current) => {
    dispatch(setCurrentApp(current));
    if (current === "Rental") {
      history.push("/rental-management/rental-list");
      // dispatch(clearMember());
      // dispatch(clearBag());
    } else {
      history.push("/");
    }
    setShowMenu(false);
  };

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };
  return (
    <nav
      className={
        showMenu === true ? ["sideDrawer", "open"].join(" ") : "sideDrawer"
      }
    >
      <div className="nav_container">
        <ul>
          <li>
            <img className="userIcon" src={userIcon} />
            <div style={{ margin: 10 }} onClick={() => handleLogout()}>Log out</div>
          </li>
          {currentApp === "Shopping bag" ? (
            <li
              className="linkTo"
              onClick={() => {
                handleNav("Rental");
              }}
            >
              <label className="link">Rental management</label>
              <img src={rightArrow} className="rightArrow" />
            </li>
          ) : (
            <li
              className="linkTo"
              onClick={() => {
                handleNav("Shopping bag");
              }}
            >
              <label className="link">Shopping Bag</label>
              <img src={rightArrow} className="rightArrow" />
            </li>
          )}
        </ul>
      </div>
      <div className="close">
        <img
          className="close_icon"
          src={whiteClose}
          onClick={() => setShowMenu(false)}
        />
      </div>
    </nav>
  );
};

export default SideDrawer;
