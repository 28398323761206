import Label from "../../common/Label/Label";
import "./info.scss";

const Info = () => {
  return (
    <div className="info_container">
      <div className="row">
        <Label text="How to create a rental bag " bold fontSize="20px" padding="0 0 24px 0" />
      </div>
      <div className="row">
        <Label text="1. Add a member" fontSize="16px" />
      </div>
      <div className="row">
        <Label text="2. Search for items" fontSize="16px" />
      </div>
      <div className="row">
        <Label text="3. Add items to the shopping bag " fontSize="16px" />
      </div>
    </div>
  );
};

export default Info;
