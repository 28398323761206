import "./row.scss";
import Arrow from "../../../assets/images/arrow.svg";
import IMG from "../Image/IMG";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const Row = (props) => {
  const linkTo = `/rental-management/order-details/${props.title}`;
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push(linkTo);
      }}
      key={props.title}
      className="row_container"
      style={{ backgroundColor: props.bg ? props.bg : "#fff" }}
    >
      <div className="row_middel">
        <div className="row_status">{props.title}</div>
        <div className="row_articleId">{props.subtitle}</div>
        <div className="row_articleId"></div>
      </div>
      <div className="row_right">
        <Link to={linkTo}>
          {" "}
          <IMG src={Arrow} />
        </Link>
      </div>
    </div>
  );
};

export default Row;
