import axios from "axios";
import { NoShow } from "../model/no-show";

export function reportNoShow(noShow: NoShow, storeId: string) {
    const url = `/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/noShow`;
    return axios
        .post(url, noShow);
}

export function getNoShowData(storeId: string) {
    return axios.get(`/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/noShows`);
}

export function deleteReportedNoShow(storeId: string, id: number) {
    return axios.delete(`/shoppingBagApi/rentals/brand/{rentalBrandId}/store/${storeId}/noShow/${id}`);
}
