import IMG from "../Image/IMG";
import "./searchField.scss";
import imgURL from "../../../assets/images/search.svg";
import inputClear from "../../../assets/images/InputClear.svg";
import Button from "../Button/Button";
import { useRef } from "react";

const SearchField = (props) => {
  const textBoxEl = useRef(null);
  const handleBtnClick = () => {
    textBoxEl.current.value = "";
    textBoxEl.current.focus();
    props.onClearClick(textBoxEl.current.value);
    props.showInfo(true);
  };
  return (
    <div
      className="search-container"
      style={{ width: props.width, height: props.height }}
    >
      <IMG src={imgURL} width="24px" height="22px" />
      <input
        ref={textBoxEl}
        value={props.value}
        onChange={(event) => {
          return props.onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          props.onKeyPress(event);
        }}
        placeholder={props.placeholder}
        name={props.name}
        style={{ width: "90%", border: "none", backgroundColor: "#FAF9F8" }}
        type={props.type}
      />
      {textBoxEl.current &&
        textBoxEl.current.value !== ""
        && props.value !== "" && (
          <>
            <Button
              id="1"
              onButtonClick={() => {
                handleBtnClick();
              }}
              width="55px"
              height="36px"
              backgroundColor="transparent"
              border="0px"
              position="absolute"
              right="32px"
              zIndex="1" />
            <IMG src={inputClear} width="24px" height="22px" position="absolute" right="45px" />
          </>
        )}
    </div>
  );
};
export default SearchField;
