import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import Button from "../../../components/common/Button/Button";
import MobileHeader from "../../../components/common/Header/MobileHeader/MobileHeader";
import Label from "../../../components/common/Label/Label";
import Loader from "../../../components/common/Loader/Loader";
import Item from "../../../components/Rental/Item/Item";
import {
  fetchReservations,
  fetchOrderDetails,
  setOrderReturnStatus,
} from "../../../services/rm-service";
import "./returnOrder.scss";
import { useSelector } from "react-redux";


function ReturnOrder() {
  const params = useParams();
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);
  const country = useSelector((state) => state.store.selectedRentalStore.countryId);
  const conditionStates = [
    {
      id: 3,
      value: "Needs cleaning",
      label: "NEEDS CLEANING",
    },
    {
      id: 5,
      value: "Needs cleaning & repair",
      label: "NEEDS REPAIR",
    },
    {
      id: 9,
      value: "Retired",
      label: "RETIRED",
    },
  ];
  const [selectedItem, setSelectedItem] = useState({});
  const [items, setItems] = useState({});
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState("order");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    const fetchItems = async () => {
      fetchReservations(params.orderId, storeId)
        .then((res) => {
          setItems(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/rental-management/error");
        });
    };
    setIsLoading(true);
    fetchItems();
    fetchOrder();
  }, [storeId]);

  const fetchOrder = useCallback(async () => {
    fetchOrderDetails(params.orderId, storeId, country)
      .then((res) => {
        setOrderDetails(res.data);
      })
      .catch(() => {
        history.push(`/rental-management/error`);
      });
  });

  const handleStateSelect = (item, index) => {
    const selections = Object.assign({}, selectedItem);
    selections[index] = item;
    setSelectedItem(selections);
  };
  const setOrderStatus = useCallback(
    async (request) => {
      await setOrderReturnStatus(storeId, params.orderId, request)
        .then(() => {
          setIsLoading(false);
          if (status === "order") {
            history.push("/rental-management/rental-list");
          }
          if (status === "confirm") {
            history.push(`/rental-management/order-details/${params.orderId}`);
          }
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/rental-management/error");
        });
    },
    [status]
  );
  const handleReturn = () => {
    if (status === "order") {
      if (Object.keys(selectedItem).length < items.length) {
        setIsError(true);
      } else {
        setStatus("confirm");
        setIsError(false);
      }
    }
    if (status === "confirm") {
      const req = {};
      req.orderStatus = "CLOSED";
      const reservations = [];
      items.map((item, index) => {
        const reservedItem = {};
        reservedItem.reservationId = item.reservationId;
        reservedItem.status = selectedItem[index].label;
        reservations.push(reservedItem);
      });
      req.reservations = reservations;
      setIsLoading(true);
      setOrderStatus(req);
    }
  };
  const handleFastReturn = () => {
    const request = {};
    request.orderStatus = "RETURNED";
    setIsLoading(true);
    setOrderStatus(request);
  };
  return (
    <div className="order-return-container">
      {isLoading ? <Loader /> : null}
      <MobileHeader
        pageName={status === "order" ? "Return" : "Return condition"}
      />
      <div className="title">
        <Label text="State Condition" bold fontSize="20px" />
      </div>
      <div className="rental-items">
        {items && items.length > 0
          ? items.map((item, index) => {
            return (
              <div key={index} className="item-container">
                <Item
                  data={item}
                  order={orderDetails}
                  dropdown
                  dropdownData={conditionStates}
                  selectedItem={
                    Object.prototype.hasOwnProperty.call(selectedItem, index)
                      ? selectedItem[index]
                      : {}
                  }
                  defaultValue="Return condition"
                  onStateSelect={(option) => {
                    handleStateSelect(option, index);
                  }}
                />
              </div>
            );
          })
          : null}
        <div
          className="error-container"
          style={{ display: isError ? "block" : "none" }}
        >
          <Label text="You need to state condition on all to return" error />
        </div>

        <div className="btn-container">
          <Button
            text={status === "order" ? "Return" : "Confirm"}
            width="100%"
            height="48px"
            type="primary"
            onButtonClick={() => {
              handleReturn();
            }}
          />
        </div>
      </div>
      {status === "order" ? (
        <div className="fast-return">
          <Label text="Fast return" bold fontSize="20px" />
          <div className="btn-container">
            <Button
              text="Return and inspect later"
              width="100%"
              height="48px"
              type="secondary"
              onButtonClick={() => {
                handleFastReturn();
              }}
            />
          </div>
          <Label
            text="If you don’t have time to control the condition of the rental you can do a fast return.
        Make sure to handle the condition later."
          />
        </div>
      ) : null}
    </div>
  );
}

export default ReturnOrder;
