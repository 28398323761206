import { getItemStatusCopy } from "../../../Utils/ItemStatusConstants";
import CommonDropdown from "../../common/Dropdown/CommonDropdown";
import IMG from "../../common/Image/IMG";
import Label from "../../common/Label/Label";
import "./item.scss";

const Item = (props) => {
  const item = props.data ? props.data : {};
  const dropdownProps = { boxWidth: "57%" };
  const conditionStates = getItemStatusCopy();

  return (
    <div className="item-container">
      <div className="left">
        <div className="img-container">
          <IMG
            width="100%"
            height="100%"
            src={item.rentalPhotos.preview.medium}
          />
        </div>
      </div>
      <div className="right">
        <div className="row">
          <Label text={item.articleContent[0].productName} />
          <Label text={item.displayItemId} />
        </div>
        <div className="row">
          <div className="inside-row">
            <div className="column">
              <Label text="Size" />
            </div>
            <div className="column">
              <Label text={item.variant.value} />
            </div>
          </div>
          <div className="inside-row">
            <div className="column">
              <Label text="Price" />
            </div>
            <div className="column">
              <Label text={`${item.price?.total} ${item.price?.currency}`} />
            </div>
          </div>
        </div>
        {props.dropdown ? (
          <div className="row">
            <CommonDropdown
              options={props.dropdownData}
              selectedItem={props.selectedItem}
              defaultValue={props.defaultValue}
              onItemSelect={props.onStateSelect}
              dropdownProps={dropdownProps}
            />
          </div>
        ) : (
          <div className="row">
            <Label text="Return condition" />
            <Label
              error={props.order.status === "RETURNED" ? true : false}
              bold
              text={
                item.returnInspectionType != null && conditionStates[item.returnInspectionType] != null
                  ? conditionStates[item.returnInspectionType]
                  : "Not stated"
              }
              fontSize="16px"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
