import Label from "../../common/Label/Label";
import { useSelector } from "react-redux";

import "./summary.scss";

const Summary = (props) => {
  const member = useSelector((state) => state.member);
  const orders = props.response ? props.response : {};
  return (
    <div className="summary_container_SB">
      <div className="row">
        <Label text="Rental number" fontSize="13px" bold />
        <Label text={orders.orderId} fontSize="13px" />
      </div>
      <div className="row">
        <Label text="Rental period" fontSize="13px" bold />
        <Label text={`${orders.orderDate} - ${orders.endDate}`} fontSize="13px" />
      </div>
      <div className="row">
        <Label text="Total cost" fontSize="13px" bold />
        <Label text={`${orders.totalAmount.value} ${orders.totalAmount.currency}`} fontSize="13px" />
      </div>
      <div className="row">
        <Label text="Member" fontSize="13px" bold />
        <Label text={`${member.firstName} ${member.lastName}`} fontSize="13px" />
      </div>
      <div className="row">
        <Label text="Email address" fontSize="13px" bold />
        <Label text={member.emailAddress} fontSize="13px" />
      </div>
      <div className="row">
        <Label text="Member ID" fontSize="13px" bold />
        <Label text={member.customerLoyaltyId} fontSize="13px" />
      </div>
    </div>
  );
};

export default Summary;
