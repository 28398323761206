import Button from "../../common/Button/Button";
import Label from "../../common/Label/Label";
import "./noShowInfo.scss";
import { useHistory } from "react-router";

const NoShowInfo = (props) => {
  const linkTo = "/no-show";
  const history = useHistory();
  const handleRedirectTo = () => history.push(linkTo);
  return (
    <div className="no_show_info_container">
      <div className="row">
        <Label text="Did the customer not come to the appointment? "
          bold fontSize="20px" padding="0 0 32px 0" />
      </div>
      <div className="tell_us">
        <Button
          onButtonClick={handleRedirectTo}
          text={props.buttonText}
          type="primary"
          width="206px"
          height="48px"
          fontSize="14px"
          color="#FFFFFF"
          border="none"
          backgroundColor="#222222"
          fontFamily="HM Sans Regular"
          letterSpacing="1.08px"
        />
      </div>
    </div>
  );
};

export default NoShowInfo;
