import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import bagIcon from "../../../../assets/images/bagIcon.svg";
import Logo from "../../Logo/Logo";
import SideDrawerButton from "../../SideDrawerButton/SideDrawerButton";
import "./header.scss";
import { getStoresAndRentalBrandIdFromApi } from "../../../../services/brandId-service";
import { useCallback, useEffect, useState } from "react";
import CommonDropdown from "../../Dropdown/CommonDropdown";
import { getDropdownOptionsFromSameKeyValue } from "../../../../Utils/app-utils";
import { storeInfo } from "../../../../features/stores-slice";
import { useHistory, useLocation } from "react-router";
import { clearMember } from "../../../../features/member-slice";
import { clearBag } from "../../../../features/bag-slice";
import { clearItemsFromSummery } from "../../../../features/item-summery-slice";
// TO DO Jira BTCCCM-1801

const Header = (props) => {
  const [selectedStore, setSelectedStore] = useState("");
  const [storesDropdownOption, setStoresDropdownOption] = useState([]);
  const items = useSelector((state) => state.shoppingBag.items);
  const currentApp = useSelector((state) => state.shoppingBag.currentApp);
  const dispatch = useDispatch();
  const linkToRetalBag = `/shopping-bag/rental-bag`;
  const linkToStartPage = `/shopping-bag/add-rental`;
  const dropdownProps = {
    boxWidth: "250px",
    background: "none",
    border: "none",
    spaceToArrow: "70px",
    selectBoxWidth: "90px",
    selectedBoxheight: "25px",
    selectedItemsBorder: "none",
    selectBoxPosition: "absolute",
    selectBoxLeft: "40%",
    selectBoxTop: "25px",
    itemsLeft: "81px",
    itemsTop: "50px"
  };
  const history = useHistory();
  const location = useLocation();
  const storeId = useSelector((state) => state.store.selectedRentalStore.storeId);

  useEffect(() => {
    getStoresOptions();
  }, []);

  const getStoresOptions = useCallback(() => {
    getStores();
  }, [selectedStore]);

  const getStores = useCallback(async () => {
    let storesOption = [];
    let storesOptionsData = [];
    storesOptionsData = await getStoresAndRentalBrandIdFromApi();
    if (storesOptionsData.assignedStores != null && storesOptionsData.assignedStores.length > 0) {
      storesOption = getDropdownOptionsFromSameKeyValue(storesOptionsData.assignedStores);
      if (storesOption) {
        setStoresDropdownOption(storesOption);
        setSelectedStore(storesOption[0]);
        getStoreData(selectedStore?.id, selectedStore?.id?.slice(0, 2));
      }
    } else {
      history.push("/shopping-bag/error");
    }
  });

  const getStoreData = (storeId, countryId) => {
    if (storeId !== undefined && countryId !== undefined) {
      const storeAndCountry = { storeId, countryId };
      dispatch(storeInfo(storeAndCountry));
    }
  };

  useEffect(() => {
    getStoreData(selectedStore?.id, selectedStore?.id?.slice(0, 2));
  }, [selectedStore]);

  const handleStoreChange = (value) => {
    setSelectedStore(value);
    getStoreData(value?.id, value?.id?.slice(0, 2));
    if (storeId !== value.id) {
      dispatch(clearMember());
      dispatch(clearBag());
      dispatch(clearItemsFromSummery());
    }
  };

  return (
    <>
      <header className="header">
        <nav className="headerNav">
          <div className="sideDrawerBtn">
            <SideDrawerButton click={props.sideDrawerButtonToggle} />
          </div>
          <div className="spacer">
            {storesDropdownOption &&
              storesDropdownOption.length > 1
              && (location.pathname === "/" ||
                location.pathname === "/rental-management/rental-list" ||
                location.pathname === "/shopping-bag/add-rental" || location.pathname === "shopping-bag/add-member") ?
              <CommonDropdown
                defaultValue={selectedStore}
                options={storesDropdownOption}
                selectedItem={selectedStore}
                onItemSelect={(storeFromDropdown) => handleStoreChange(storeFromDropdown)}
                dropdownProps={dropdownProps}
              />
              :
              <Link to={linkToStartPage}>
                <div className="logo">
                  <Logo />
                  {currentApp === "Shopping bag" ? "RENTAL Shopping bag" : "RENTAL"}
                </div>
              </Link>
            }
          </div>
          <div className="menus"></div>
          <div className="userProfile">
            {currentApp === "Shopping bag" ? (
              <div className="shoppingBag">
                <Link to={items && items.length > 0 ? linkToRetalBag : ""}>
                  <div className="amount">
                    {items && items.length > 0 ? items.length : 0}
                  </div>
                  <img className="bagIcon" src={bagIcon} />
                </Link>
              </div>
            ) : null}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
